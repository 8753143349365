import React, { useState } from 'react';

import { Maybe } from 'graphql/jsutils/Maybe';

import Icon from '@components/Icon';
import { OneColumn } from '@components/Layouts/OneColumn';
import Title from '@components/Title';

import SignupForm, { FormValues } from './Form';
import { useCreateAccountMutation } from './query';

import { useNotifications } from '@use-cases/notifications';

import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';

import { ErrorCode } from '@typings/ErrorCode';

export interface FormMutatedValues {
  email: string;
  firstName: string;
  lastName: string;
  youthFlag: boolean;
  yearOfBirth: number | undefined;
}

interface Props {}
const Signup: React.FC<Props> = () => {
  const { t, i18n } = useTranslation();
  const langcode = i18n.language;
  const { addError, clearNotification } = useNotifications();
  const [createAccount] = useCreateAccountMutation();
  const { login } = useAppConfig();
  const [accountEmail, setAccountEmail] = useState('');

  const submitCreateAccount = async (
    values: FormMutatedValues,
    recaptchaToken: Maybe<string>
  ) => {
    clearNotification('age-validation');
    await createAccount({
      variables: {
        ...values,
        langcode,
        recaptchaToken,
      },
    });
    setAccountEmail(values.email);
    clearNotification(`error-${ErrorCode.Validation}`);
  };

  const handleSubmit = async (
    values: FormValues,
    recaptchaToken: Maybe<string>
  ) => {
    if (values.youthFlag === 'yes') {
      const mutationValues = {
        ...values,
        youthFlag: false,
        yearOfBirth: undefined,
      };
      await submitCreateAccount(mutationValues, recaptchaToken);
    } else {
      const mutationValues = {
        ...values,
        youthFlag: true,
        yearOfBirth: parseInt(values.yearOfBirth, 10),
      };
      const currentYear = new Date().getFullYear();
      if (currentYear - mutationValues.yearOfBirth < 16) {
        addError(
          t(
            'signup.form.error.younger_than_16',
            'We cannot create a new account if you are less than 16.'
          ),
          {
            id: 'age-validation',
          }
        );
      } else {
        await submitCreateAccount(mutationValues, recaptchaToken);
      }
    }
  };

  return (
    <OneColumn className="mb-20">
      <Title className="h2">
        {t('signup.header.label', 'Create an Account')}
      </Title>
      {accountEmail ? (
        t(
          'signup.header.success',
          'An email has been sent to {{email}}. Follow the instructions in the email to activate your new your account.',
          { email: accountEmail }
        )
      ) : (
        <>
          <p>
            <span className="mr-3">
              {t('signup.header.info', 'Already have a My Rotary Account?')}
            </span>
            <button
              type="button"
              className="uppercase inline-flex items-center font-bold text-bright-blue-600 text-xs leading-xs-heading"
              onClick={() => login()}
            >
              {t('signup.header.login-link-label', 'Sign In')}
              <Icon
                className="ml-2"
                name="arrows/right"
                color="bright-blue-600"
                size="12"
              />
            </button>
          </p>
          <SignupForm handleSubmit={handleSubmit} />
        </>
      )}
    </OneColumn>
  );
};

export default Signup;
